












import { Component, Vue, PropSync } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({
  components: {},
})
export default class MobileNavigationBar extends Vue {
  @PropSync('show') drawer!: boolean

}
